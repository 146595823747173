import { CombineSubscriptions, DestroySubscribers } from "ngx-destroy-subscribers";
import { fromEvent, Unsubscribable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { Controller } from '@stimulus/core';
import { ZipcodeLookupHelper } from '../helpers/zipcode-lookup-helper';

@DestroySubscribers({
  destroyFunc: 'disconnect'
})
export default class AddressFormController extends Controller {
  public static targets = ['postalCode', 'houseNumber', 'houseNumberExtension', 'line1', 'line1Street', 'line1Address', 'line2', 'city', 'state', 'countryCode',
    'houseNumberContainer', 'houseNumberExtensionContainer', 'line2Container', 'zipcodeLookupError'];

  private zipcodeLookupUrl: string;
  private zipcodeCountries: string[] = [];
  private streetAndNumberCountries: string[] = [];
  private hideStateCountries: string[] = [];

  private readonly postalCodeTarget!: HTMLInputElement;
  private readonly houseNumberTarget!: HTMLInputElement;
  private readonly houseNumberExtensionTarget!: HTMLInputElement;
  private readonly line1Target!: HTMLInputElement;
  private readonly line1StreetTarget!: HTMLDivElement;
  private readonly line1AddressTarget!: HTMLDivElement;
  private readonly line2Target!: HTMLInputElement;
  private readonly cityTarget!: HTMLInputElement;
  private readonly stateTarget: HTMLInputElement | null;
  private readonly countryCodeTarget!: HTMLSelectElement;
  private readonly houseNumberContainerTarget!: HTMLElement;
  private readonly houseNumberExtensionContainerTarget!: HTMLElement;
  private readonly line2ContainerTarget!: HTMLElement;
  private readonly stateContainerTarget: HTMLElement | null;
  private readonly hasStateTarget: boolean;
  private readonly hasStateContainerTarget: boolean;
  private readonly zipcodeLookupErrorTarget!: HTMLElement;

  private zipcodeLookupHelper: ZipcodeLookupHelper;

  @CombineSubscriptions()
  private subscriber: Unsubscribable;

  public connect() {
    this.zipcodeLookupUrl = this.data.get('zipcodeUrl');
    this.zipcodeCountries = JSON.parse(this.data.get('zipcodeCountries'));
    this.streetAndNumberCountries = JSON.parse(this.data.get('streetNumberCountries'));
    if (this.data.has('hideStateCountries')) {
      this.hideStateCountries = JSON.parse(this.data.get('hideStateCountries'));
    }

    this.zipcodeLookupHelper = new ZipcodeLookupHelper(this.zipcodeLookupUrl,
      this.postalCodeTarget,
      this.houseNumberTarget,
      this.line1Target,
      this.cityTarget,
      (this.hasStateTarget && this.stateTarget),
      this.countryCodeTarget,
      this.zipcodeLookupErrorTarget,
      this.zipcodeCountries);
    
    // This timeout makes sure that the form data on going back to the page, is fully loaded before attaching the zipcode lookup
    setTimeout(() => {
      this.updateFormLayout();
      this.zipcodeLookupHelper.attachZipcodeLookup().forEach(sub => {
        this.subscriber = sub;
      })
    }, 10);

    this.subscriber = fromEvent(this.countryCodeTarget, 'change').subscribe(() => {      
        this.updateFormLayout();
    });
  }

  private updateFormLayout() {
    const streetAndNumber = (this.streetAndNumberCountries.indexOf(this.countryCodeTarget.value) > -1);

    this.houseNumberContainerTarget.classList.toggle('d-none', !streetAndNumber);
    this.houseNumberContainerTarget.querySelector('input').toggleAttribute('disabled', !streetAndNumber);

    this.houseNumberExtensionContainerTarget.classList.toggle('d-none', !streetAndNumber);
    this.houseNumberExtensionContainerTarget.querySelector('input').toggleAttribute('disabled', !streetAndNumber);

    this.line2ContainerTarget.classList.toggle('d-none', streetAndNumber);
    this.line2ContainerTarget.querySelector('input').toggleAttribute('disabled', streetAndNumber);

    this.line1StreetTarget.querySelector('input').toggleAttribute('disabled', !streetAndNumber);
    this.line1StreetTarget.classList.toggle('d-none', !streetAndNumber);

    this.line1AddressTarget.querySelector('input').toggleAttribute('disabled', streetAndNumber);
    this.line1AddressTarget.classList.toggle('d-none', streetAndNumber);

    if (this.hasStateContainerTarget) {
      const hideState = (this.hideStateCountries.indexOf(this.countryCodeTarget.value) > -1);
      this.stateContainerTarget.classList.toggle('d-none', hideState);
    }
  }

  public clearAddressFields() {
    [this.postalCodeTarget, this.houseNumberTarget, this.houseNumberExtensionTarget, this.line1Target, this.line2Target,
    this.cityTarget, this.stateTarget].forEach(e => e.value = null);
  }
}
