import { Controller } from '@stimulus/core';
import { CookieHelper } from '../helpers/cookie-helper';
import { LogHelper } from '../helpers/log-helper';

declare global {
  interface Window {
    fbq: any;
    gtag: Function;
    uetq: any;
    tp: Function;
  }
}

export default class DataLayerController extends Controller {
  public static targets = ['clientID', 'sessionID'];

  private clientIDTarget?: HTMLInputElement;
  private readonly hasClientIDTarget: boolean;

  public connect() {
    if (this.data.has('onConnect')) {
      this.fireOnConnect()
    }
    if (this.hasClientIDTarget && this.hasSessionIDTarget) {
      this.fillGADataFields();
    }
  }

  /* Promotions */
  public promotionView() {
    if ((this.element as HTMLElement).offsetParent === null) {
      console.log('Skip promotion view event for hidden element', this.element);
      return
    }

    window.gtag('event', 'eec.promotionView',
      {
        'promoView': {
          'promotions': [
            {
              'name': this.data.get('promotionName')
            }
          ]
        }
      }
    );
  }

  public salesRequestFormSubmit() {
    window.gtag('event', 'salesRequestFormSubmit');
  }

  public promotionClick() {
    window.gtag('event', 'eec.promotionClick',
      {
        'promoClick': {
          'promotions': [{
            'name': this.data.get('promotionName')
          }]
        }
      }
    );
  }

  /* GA Ecommerce events */
  public viewItemList() {
    const payload = JSON.parse(this.data.get('viewItemList'));
    window.gtag('event', 'view_item_list', payload);
  }

  public viewItem() {
    const payload = JSON.parse(this.data.get('viewItem'));
    window.gtag('event', 'view_item', payload);
    this.trackFacebookDestination('ViewContent');
  }

  public addToCart() {
    const payload = JSON.parse(this.data.get('addToCart'));
    window.gtag('event', 'add_to_cart', payload);
  }

  public beginCheckout() {
    const payload = JSON.parse(this.data.get('beginCheckout'));
    window.gtag('event', 'begin_checkout', payload);
    this.trackFacebookDestination('AddToCart');
  }

  public addPaymentInfo() {
    const payload = JSON.parse(this.data.get('addPaymentInfo'));
    window.gtag('event', 'add_payment_info', payload);
  }

  public purchase() {
    if (window.uetq) {
      // Track Microsoft Advertising purchase
      const uetqPurchasePayload = JSON.parse(this.data.get('uetqPurchase'));
      window.uetq.push('event', '', uetqPurchasePayload)
    }
    const gaPurchasePayload = JSON.parse(this.data.get('purchase'));
    window.gtag('event', 'purchase', gaPurchasePayload);
    this.trackFacebookDestination('Purchase', { value: gaPurchasePayload.value, currency: gaPurchasePayload.currency });
    this.sendTrustpilotInvitation();
  }

  /* GA Custom events */

  public switchToListView() {
    window.gtag('event', 'listing.index.switchToListView');
  }

  public switchToMapView() {
    window.gtag('event', 'listing.index.switchToMapView');
  }

  public datePickerOpened() {
    this.sendListingDetailCustomEvent('listing.detail.datePickerOpened');
  }

  public datePickerLoadEarlier() {
    this.sendListingDetailCustomEvent('listing.detail.datePickerLoadEarlier');
  }

  public datePickerLoadLater() {
    this.sendListingDetailCustomEvent('listing.detail.datePickerLoadLater');
  }

  public datePickerScrollLeft() {
    this.sendListingDetailCustomEvent('listing.detail.datePickerScrollLeft');
  }

  public datePickerScrollRight() {
    this.sendListingDetailCustomEvent('listing.detail.datePickerScrollRight');
  }

  public arrivalDateChosen() {
    this.sendListingDetailCustomEvent('listing.detail.arrivalDateChosen');
  }

  public departureDateChosen() {
    this.sendListingDetailCustomEvent('listing.detail.departureDateChosen');
  }

  public datePickerApplyDates() {
    this.sendListingDetailCustomEvent('listing.detail.datePickerApplyDates');
  }

  /* Auction events */

  public bidPlaced(event: CustomEvent) {
    window.gtag('event', 'auction.bidPlaced', { increase: event.detail.increase });
  }

  public autobidPlaced() {
    window.gtag('event', 'auction.autobidPlaced');
  }

  public auctionWon() {
    window.gtag('event', 'auction.auctionWon');
  }

  /* Helper methods */

  private sendListingDetailCustomEvent(eventName: string) {
    const payload = JSON.parse(this.data.get('listingDetail'));
    window.gtag('event', eventName, payload);
  }

  private fillGADataFields() {
    window.gtag('get', this.data.get('gaMeasurementId'), 'client_id',
      clientId => { this.clientIDTarget.value = clientId });
    window.gtag('get', this.data.get('gaMeasurementId'), 'session_id',
      sessionId => { this.sessionIDTarget.value = sessionId });
  }

  private fireOnConnect() {
    const name = this.data.get('onConnect');
    const func = this[name];
    if (func) {
      this.restrictMultipleTriggers(func);
    } else {
      console.error('Function not found: ' + name);
    }
  }

  private restrictMultipleTriggers(fn) {
    if (this.data.has('fireOnceId')) {
      const fireOnceId = this.data.get('fireOnceId');
      const firedOnceIds: string[] = CookieHelper.getSerialized('dlFireOnceIds') || [];
      if (firedOnceIds.includes(fireOnceId)) {
        return
      } else {
        CookieHelper.setSerialized('dlFireOnceIds', firedOnceIds.concat(fireOnceId));
      }
    }
    fn.apply(this);
  }

  private trackFacebook(eventName: string, payload?: any) {
    if (window.fbq) {
      window.fbq('track', eventName, payload)
    }
  }

  private trackFacebookDestination(eventName: string, payload?: any) {
    const productIds = JSON.parse(this.data.get('fbProductIds'));

    if(payload) {
      this.trackFacebook(eventName, {
        content_ids: productIds,
        content_type: 'destination',
        ...payload
      });
    } else {
      this.trackFacebook(eventName, {
        content_ids: productIds,
        content_type: 'destination'
      });
    }
  }

  private sendTrustpilotInvitation() {
    if (window.tp) {
      const payload = this.parseJSONDataAttribute('trustpilotInvitation');
      if (payload) {
        window.tp("createInvitation", payload);
      }
    }
  }

  private parseJSONDataAttribute(attribute: string) {
    const value = this.data.get(attribute);
    if (value) {
      try {
        return JSON.parse(value);
      } catch (e) {
        LogHelper.logError(`Failed to parse JSON data attribute: ${attribute}`, e);
        return {}
      }
    }
  }
}