// eslint-disable-next-line no-unused-vars
import { fromEvent, Unsubscribable, Subject, interval } from 'rxjs';
import { DestroySubscribers, CombineSubscriptions } from 'ngx-destroy-subscribers';
import { debounceTime, tap } from 'rxjs/operators';
import { FormatHelper } from '../helpers/format-helper';
import FormController from './form_controller';

@DestroySubscribers({
  destroyFunc: 'disconnect'
})
export default class BookingDetailsController extends FormController {
  public static targets = ['bookingDetailForm', 'summary', 'countdownTimer', 'input', 'editGroupSize', 'staticGroupSize'];

  private formSubject: Subject<any> = new Subject();
  private readonly bookingDetailFormTarget!: HTMLFormElement;
  private readonly inputTargets!: HTMLElement[];
  private readonly summaryTargets!: HTMLElement[];
  private readonly countdownTimerTarget!: HTMLElement;
  private readonly hasCountdownTimerTarget: boolean;
  private readonly editGroupSizeTarget!: HTMLDivElement;
  private readonly staticGroupSizeTarget!: HTMLDivElement;

  @CombineSubscriptions()
  private subscriber: Unsubscribable;

  public connect() {
    super.connect();
    this.attachFormCalculation()
    if (this.hasCountdownTimerTarget) {
      this.setupCountdownTimer();
    }
  }

  // Ensures the summary is shown after a back-button browser navigation 
  public onPageshow() { 
    this.updateSummary() 
  }

  public onAjaxSuccessJson(event: CustomEvent) {
    super.onAjaxSuccessJson(event);
    const [data] = event.detail;
    if (data?.itemHTML) {
      this.summaryTargets.forEach(summary => {
        summary.innerHTML = data.itemHTML;
        summary.classList.remove('loading');
      })
    }
  }

  public onAjaxError(event: CustomEvent) {
    super.onAjaxError(event);
    this.summaryTargets.forEach(summary => {
      summary.classList.remove('loading');
    })
  }

  public amountChanged(event: CustomEvent) {
    const item = event.currentTarget as HTMLElement;
    const checkbox = item.parentNode.querySelector("input[type='checkbox']") as HTMLInputElement;
    checkbox.checked = true;
  }

  public editGroupSizeButton() {
    this.editGroupSizeTarget.classList.remove('d-none')
    this.staticGroupSizeTarget.classList.add('d-none')
  }

  private attachFormCalculation() {
    this.inputTargets.forEach(input => {
      this.subscriber = fromEvent(input, 'change').subscribe(() => {
        this.formSubject.next();
      })
    });
    this.subscriber = this.formSubject.pipe(
      tap(() =>
        this.summaryTargets.forEach(summary => {
          summary.classList.add('loading')
        })
      ),
      debounceTime(100)
    ).subscribe(() => {
      this.updateSummary();
    })
  }

  private updateSummary() {
    const params = new URLSearchParams(new FormData(this.bookingDetailFormTarget) as any);
    const summaryTarget = this.summaryTargets[0];
    const baseUrl = summaryTarget.dataset.url;
    const url = new URL(baseUrl, window.location.origin);
    params.forEach((value, key) => url.searchParams.append(key, value));

    return fetch(url.toString(), {
      headers: { 'X-Error-Message-Only': 'true' },
      method: 'GET',
      redirect: 'error'
    })
      .then(res => res.ok ? res.text() : res.json().then(json => Promise.reject(json)))
      .then(text => {
        this.summaryTargets.forEach(summary => {
          summary.innerHTML = text;
          summary.classList.remove('loading');
        });
      })
      .catch(error => {
        const message = (error && error.errorMessage) ? error.errorMessage : summaryTarget.dataset.errorMessage;
        this.summaryTargets.forEach(summary => {
          summary.innerHTML = message;
          summary.classList.remove('loading');
        });
      });
  }

  private setupCountdownTimer() {
    this.subscriber = interval(1000).pipe(
    ).subscribe(() => {
      const now = new Date();
      const currentEndsAt = parseInt(this.countdownTimerTarget.dataset.bookingWindowEndsAt, 0);
      const delay = currentEndsAt - +(Math.floor(+now / 1000));
      this.countdownTimerTarget.innerHTML = `${FormatHelper.formatTime(delay)}`;
    });
  }
}
